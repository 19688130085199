var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8 padCustom"},[_c('div',{staticClass:"sectionTwoBoxWrap"},[_c('div',{staticClass:"insideItemBoxWrap"},[(_vm.loading)?_c('div',{staticClass:"d-flex flex-row sectionTwoSekelton",staticStyle:{"width":"100%","justify-content":"space-between"}},[_c('v-skeleton-loader',{staticClass:"ma-2",attrs:{"type":"article","width":"47%","height":"100%"}}),(_vm.options.dateType == 'today')?_c('v-skeleton-loader',{staticClass:"ma-2",attrs:{"type":"article","width":"47%","height":"100%"}}):_vm._e()],1):_c('draggable',{staticClass:"section-two row",class:_vm.isDisable ? 'section-two-in-active' : '',attrs:{"group":"cards-tag","disabled":_vm.isDisable},on:{"change":_vm.updateOrder},model:{value:(_vm.dashboardSort.section_2),callback:function ($$v) {_vm.$set(_vm.dashboardSort, "section_2", $$v)},expression:"dashboardSort.section_2"}},[_vm._l((_vm.dashboardSort?.section_2),function(row,i){return [(
              row.card_name == 'Trips' ||
                (row.card_name == 'Vehicle Status' &&
                  _vm.options.dateType == 'today')
            )?_c('div',{key:i,staticClass:"col-lg-6",attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"largeItemBoxWrap"},[_c('div',{staticClass:"largeItemBoxHeading"},[_c('div',{staticClass:"dflexCustom"},[_vm._v(" "+_vm._s(row.card_name)+" "),(row.card_name == 'Trips')?_c('span',{staticClass:"open-link",on:{"click":function($event){return _vm.editItem()}}},[_c('Icons',{attrs:{"name":"external-link"}})],1):_vm._e()]),(_vm.visibleButton && row.card_name === 'Trips')?_c('button',{staticClass:"reset-button chartFilterButton",on:{"click":_vm.resetToInitialStage}},[_c('v-icon',[_vm._v("mdi-reload")])],1):_vm._e()]),(row.card_name == 'Trips')?_c('div',{staticClass:"insideLargeItemBoxContentWrap"},[_c('div',{staticClass:"apexBasicChart apexTripsChart"},[_c('ApexBarChartWithPlotOptions',{ref:"tripChart",refInFor:true,attrs:{"options":_vm.options,"data":_vm.dashboadVehicleTrips.trips_chart_data,"width":'100%',"height":'250px',"cursor-drill":_vm.cursorDrill},on:{"resetCursorValue":_vm.resetCursorValue,"resetButton":_vm.resetButton}})],1),_vm._l((row.keys),function(val,st){return _c('div',{key:st,staticClass:"largeItemContentBoxWrap tripsContentBoxes",class:val == 'active_trips' && 'active',staticStyle:{"pointer-events":"none"},on:{"click":function($event){val == 'active_trips' && _vm.openActiveModal()}}},[_c('div',{class:['largeItemsContentBoxes', _vm.getIconColor(val)]},[_c('div',{staticClass:"iconBox"},[_c('Icons',{attrs:{"name":_vm.getIconName(val)}})],1),_c('div',{staticClass:"contentBox"},[_c('p',{class:val == 'active_trips' ? 'oopen-link' : ''},[_c('span',[_vm._v("Total")]),_vm._v(" "+_vm._s(_vm.$t(`${val}`))+" ")])]),_c('div',{staticClass:"valueBox tripsValueBox"},[_vm._v(" "+_vm._s(_vm.dashboadVehicleTrips[val])+" ")])])])})],2):(row.card_name === 'Vehicle Status')?_c('div',{class:[
                  'insideLargeItemBoxContentWrap',
                  { active: _vm.options.tenant_id },
                ]},[_c('div',{staticClass:"apexDonutChart"},[_c('ApexDonutChart',{attrs:{"data":_vm.dashboadVehicleStatus,"titles":row.keys,"width":'100%',"height":'270px'}})],1),_vm._l((row.keys),function(val,st){return _c('div',{key:st,staticClass:"largeItemContentBoxWrap",class:val,on:{"click":function($event){(_vm.options.tenant_id || _vm.options.fleetId) &&
                      _vm.goToTelematics(val)}}},[_c('div',{class:['largeItemsContentBoxes', _vm.getIconColor(val)]},[_c('div',{staticClass:"iconBox"},[_c('Icons',{attrs:{"name":"grid-box"}})],1),_c('div',{staticClass:"contentBox"},[_c('p',{class:_vm.options.tenant_id || _vm.options.fleetId
                            ? 'open-link'
                            : ''},[_c('span',[_vm._v("Total")]),_vm._v(" "+_vm._s(_vm.$t(`vehicle_${val}`))+" ")])]),_c('div',{staticClass:"valueBox"},[_vm._v(" "+_vm._s(_vm.dashboadVehicleStatus[val])+" ")])])])})],2):_vm._e()])]):_vm._e()]})],2)],1)]),(_vm.isActiveModal)?_c('Modal',{attrs:{"title":"active_trips","is-visible":_vm.isActiveModal,"is-view":"","toggle":() => (_vm.isActiveModal = false)}},[_c('active-trips',{attrs:{"options":_vm.options}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }